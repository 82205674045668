.bnt {
  width: 174px;
  height: 104px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--c-grey-secondary);
  border-radius: 2px;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: var(--c-primary);
  cursor: pointer;
  outline: none;
  background-color: transparent;

  &:hover {
    border: none;
    background-color: var(--c-light-grey);
  }
}

.bntActive {
  background-color: var(--c-info);
  border: none;
  color: var(--c-white);
  &:hover {
    border: none;
    background-color:  var(--c-info-darken);
    color: var(--c-white);
  }
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: var(--c-grey-darken);

  &.subtitleActive {
    color: var(--c-white);
  }
}

.bntInActive {
  border: none;
  background: var(--c-light-grey);
  cursor: not-allowed;

  &:hover {
    border: none;
    background-color: var(--c-light-grey);
  }

   * {
    color: var(--c-grey-darken);
   }
}

.bntWrapper {
  display: inline-flex;
  padding: 7px;
}
