.col {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  margin-left: 27px;

  &:after {
    content: "";
    height: 100%;
    width: 1px;
    background-color: var(--c-grey-secondary);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 13px);
  }
}

.titleGray {
  text-align: right;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--c-grey-darken);
  margin-bottom: 5px;
}

.titleStrong {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  color: var(--c-primary);
}
