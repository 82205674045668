.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &>div {
    display: flex;
    width: 50px;
    height: 50px;

    div {
      width: 35px;
      height: 35px;
    }

  }
}
