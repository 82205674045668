.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 8px 12px;
  margin-bottom: 4px;
}

.bottomMargin {
  margin-bottom: 8px;
}

.headerBottom {
  display: flex;
  justify-content: space-between;
  background: var(--c-grey-secondary);
  padding: 24px 12px 20px;
  position: relative;
  &:before,
  &:after {
    content: '';
    height: 100%;
    width: 12px;
    position: absolute;
    top: 0;
    background: var(--c-grey-secondary);
  }

  &:before {
    left: -12px;
  }
  &:after {
    right: -12px;
  }
}

.delimiter {
  width: 100%;
  height: 4px;
}

.wrapper {
 display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  align-items: center;
}

.rateText {
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  text-align: right;
  color: var(--c-primary);
}

.rateWrapper {
  margin-left: 24px;
  margin-right: 24px;
}

.popover {
  background-color: var(--c-white);
  border-radius: 2px;
  border: 1px solid var(--c-grey-secondary);
  padding: 12px;
  display: none;
  flex-direction: column;
  position: absolute;
  left: auto;
  right: 0;
  top: calc(100% - 1px);
  z-index: 100;
}


.navItemLink {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 1.55;
  font-weight: 500;
  color: var(--c-primary);
  position: relative;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: var(--c-grey-secondary);
  }

  &:not(:last-child) {
    margin-bottom: 24px;
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--c-grey-secondary);
      position: absolute;
      left: auto;
      right: 0;
      bottom: -12px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 12px;
  }
}

.popoverWrapper {
  position: relative;
  cursor: pointer;
  &:hover {
    .popover {
      display: flex;
    }
  }
}
.btn {
  width: 34px;
  height: 34px;
  border-radius: 2px;
  border: 1px solid var(--c-grey-secondary);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3px;
  cursor: pointer;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border: 1px solid var(--c-grey-secondary);
  border-radius: 2px;
}

.bottomTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: var(--c-primary);
}

.bottomSubTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--c-grey-darken);
}

.bgDanger {
  background: var(--c-danger);
  position: relative;
  &:before,
  &:after {
    content: '';
    height: 100%;
    width: 12px;
    position: absolute;
    top: 0;
    background: var(--c-danger);
  }

  &:before {
    left: -12px;
  }
  &:after {
    right: -12px;
  }
}

.bgInfo {
  background-color: var(--c-info);
  position: relative;
  &:before,
  &:after {
    content: '';
    height: 100%;
    width: 12px;
    position: absolute;
    top: 0;
    background: var(--c-info);
  }

  &:before {
    left: -12px;
  }
  &:after {
    right: -12px;
  }
}

.spacer {
  margin-right: 12px;
}

.label {
  display: flex;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--c-grey-darken);
  margin-left: 12px;
  margin-right: 12px;
  max-width: 300px;
}

@media (min-width: 1450px) {
  .popover {
    left : 0;
    right: auto;
  }
}

