@import 'scss/scss-variable';
.topBlockLabel {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  color: var(--c-primary);
  padding: 0 $grid-gutter;
  margin-bottom: 12px;
}

.sidebarContainer {
  flex: 0 0 330px;
  max-width: 330px;
  padding: 0 12px;
  width: 100%;
  text-align: center;
}

.stepsContent {
  display: flex;
  flex-direction: column;
  flex: 0 0 540px;
  max-width: 540px;
  padding: 0 $grid-gutter;
  width: 100%;
}

.hideBefore {
  &:before {
    display: none;
  }
}

.inputRow {
  margin-bottom: 24px;
}

.buttonsRow {
  margin-top: 48px;
  display: flex;
  justify-content: flex-end;
}

.ml {
  margin-left: 24px;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  &>div{
    width: 70px;
    height: 70px;
    div {
      width: 60px;
      height: 60px;
    }
  }
}

.colStart {
  label {
    position: relative;
    left: 0;
    top: 24px;
  }
}

.error {
  font-size: 18px;
  line-height: 1.4;
  color: var(--c-danger);
}
