.bnt {
  min-width: 196px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-family: inherit;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  line-height: 1.6;
  border-radius: 2px;
  transition: .2s ease;
  border: 0;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

.btnInfo {
  background-color: var(--c-info);

  &:hover {
    background-color: var(--c-info-darken);
  }
}

.btnTransparent {
  background: none;
  border: 1px solid var(--c-primary);
  color: var(--c-primary);

  &:hover {
    background-color: var(--c-light-grey);
  }
}

.nonBorder {
  background: none;
  border: none;
  color: var(--c-primary);

  &:hover {
    color: var(--c-dark);
  }
}

.strongUnderline {
  width: fit-content;
  min-width: auto;
  height: auto;
  position: relative;
  padding: 0;
  color: var(--c-primary);
  text-transform: uppercase;
  transition: 0.9s;

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 0;
    background-color: var(--c-danger);
  }

  &:hover {
    opacity: 0.9;
  }
}

.btnUnderline {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 500;
  color: var(--c-info);
  text-decoration: underline;
  background: transparent;
  outline: none;
  border: none;

  &:hover {
    color: var(--c-info-darken);
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.danger {
  background-color: var(--c-danger);
}

.simple {
  padding: 0;
  min-width: auto;
  height: auto;
}

.tagType {
  min-width: auto;
  height: auto;
  min-height: 48px;
  padding: 6px 12px;
  border: 1px solid var(--c-grey-secondary);
  border-radius: 12px;
  line-height: 1.55;
  color: var(--c-primary);

  &:hover {
    background: rgba(221,140,147,.08);
  }
}


