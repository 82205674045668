@import 'scss/scss-variable';
.stepsContent {
  display: flex;
  flex-direction: column;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  margin-left: 8.33333%;
  padding: 0 $grid-gutter;
  width: 100%;
}
