.expertInfo {
  max-width: 350px;
}

.title {
  font-size: 24px;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-weight: 700;
  color: var(--c-primary);
}

.area {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
  color: var(--c-primary);
}

.expertLastSeen {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  color: var(--c-grey-darken);
}

.expertAdress {
  position: relative;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  color: var(--c-grey-darken);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mr {
  margin-right: 10px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
