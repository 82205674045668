.navItem {
  margin-right: 40px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: var(--c-primary);
    transition: 0.2s;
  }

  &:hover {
    &:after {
      width: 100%   ;
    }
  }
}

.navItemLink {
  font-size: 18px;
  line-height: 1.4;
  color: var(--c-primary);
}
