.delimiterRow {
  display: flex;
  padding-bottom: 22px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--c-grey-secondary);
}

.row {
  display: flex;
}

.column {
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.contentCenter {
  justify-content: center;
}

.textCenter {
  text-align: center;
  margin-bottom: 14px;
}

.contentEnd {
  justify-content: flex-end;
}

.topTitle {
  font-size: 18px;
  line-height: 28px;
  color: var(--c-primary);
  font-weight: 400;
  text-align: center;
  margin-bottom: 6px;
}

.titleStrong {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--c-primary);
}

.contentBetween {
  justify-content: space-between;
}

.noBorder {
  border: none;
  padding-bottom: 0;
}

.hide {
  padding: 0;
}

.uppercaseText {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--c-grey-darken);
}

.strongText {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--c-primary);
}

.priceBlock {
  font-family: Rubik,sans-serif;
  padding: 7px 7px 7px 36px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 1.2;
  color: var(--c-primary);
}

.input {
  outline: none;
  width: 93px;
  min-width: 93px;
  input {
    font-family: Rubik,sans-serif;
    padding: 7px 7px 7px 36px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    line-height: 1.2;
    color: var(--c-primary);
    border: 1px solid var(--c-grey-secondary);
    border-radius: 2px;
    max-width: unset;
  }
}

.icon {
  width: 28px;
  height: 28px;
  font-size: 14px;
}

.label {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  color: var(--c-primary);
  padding: 4px 12px;
  border-radius: 12.5px;
  background-color: var(--c-grey-secondary);
  width: fit-content;
}

.datePicker {
  input {
    font-family: Rubik,sans-serif;
    padding: 7px;
    font-size: 16px;
    text-align: center;
    line-height: 1.2;
    color: var(--c-primary);
    font-weight: 500;
    border: 1px solid var(--c-grey-secondary);
    border-radius: 2px;
    max-width: 120px;
  }
}

.dashedDelimiter {
  display: flex;
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px dashed var(--c-grey-secondary);
}

.mbZero {
  margin-bottom: 0;
}

.totalWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.list {
  width: 100%;
  padding: 12px 24px;
  background-color: var(--c-light-grey);
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  counter-reset: popupCounter;
  li {
    position: relative;
    padding-left: 15px;
    font-size: 12px;
    line-height: 1.4;
    font-weight: 400;
    color: var(--c-grey-darken);
    margin-bottom: 10px;

    &:before {
      content: counter(popupCounter) ".";
      counter-increment: popupCounter;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  p {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 400;
    color: var(--c-grey-darken);
    white-space: pre-line;
  }
}

.w_100 {
  width: 100%;
}

.inputW100 {
  input {
    width: 100%;
    text-align: left;
    padding-left: 17px;
  }
}

.topMargin {
  margin-top: 13px;
}

.priceBlockWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 15px 3px 3px;
  height: 34px;
  min-width: 93px;
  border: 1px solid rgba(44, 72, 119, 0.1);
  border-radius: 2px;

  div {
    padding: 0;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-right: 16px;
    background: #F8F9FB;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 155%;
    color: #96A4BB;
  }
}
