.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-grey-darken);
  border: 1px solid var(--c-grey-darken);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.sidebarItem {
  position: relative;
  margin-bottom: 24px;
  padding-left: 36px;

  a,
  &>div{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: var(--c-grey-darken);
    cursor: pointer;
  }
}

.sidebarItemDoneExpert {
  .counter {
    border: none;
    background: var(--c-info);
    color: var(--c-white);
  }

  a,
  &>div {
    color: var(--c-info);
  }
}

.sidebarItemDoneFounder {
  .counter {
    border: none;
    background: var(--c-danger);
    color: var(--c-white);
  }

  a,
  &>div {
    color: var(--c-danger);
  }
}


.sidebarItemCurrent {

  .counter {
    border: none;
    background: var(--c-primary);
    color: var(--c-white);
  }

  a,
  &>div {
    color: var(--c-primary);
  }

}
