.flexRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dangerBtn {
  width: calc(100% / 2 - 12px);
  min-width: 100px;
  height: 42px;
  font-size: 16px;
}

.underlineBtn {
  width: auto;
  height: auto;
  text-transform: uppercase;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
}
