.sectionTitle {
  font-size: 42px;
  line-height: 1.2;
  font-weight: bold;
  color: var(--c-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.loginForm {
  padding-bottom: 168px;
}

.inputField {
  margin-bottom: 24px;
  display: block;
}

.error-color {
  border-color: var(--c-danger);
}

.input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 2px;
  border: 1px solid var(--c-grey);
  background-color: var(--c-white);
  font-family: inherit;
  font-size: 18px;
  color: var(--c-dark);
  resize: none;

  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::placeholder{
    color: var(--c-grey-darken);
  }
}

.loginButton {
  width: 196px;
  height: 52px;
  font-family: inherit;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  transition: 0.2s ease;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  background-color: var(--c-primary);
  &:hover {
    background: var(--c-primary-darken);
  }
}

.loginNotification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  line-height: 1.6;
  font-size: 18px;
  color: var(--c-grey-darken);
  padding: 12px 0;
  background-color: var(--c-light-grey);
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loginBlock {
  padding: 70px 68px 68px;
  background-color: var(--c-white);
  position: relative;
  min-height: 664px;
}

.loginBlockWrapper {
  margin: 66px 0 68px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -60px;
    left: -40px;
    width: 190px;
    height: 164px;
    background: url("../../images/login-form-bg.svg") no-repeat center/cover;
    z-index: 0;
  }
}

.verificationBlock {
  padding: 16px;
  background: var(--c-light-grey);
  margin-bottom: 24px;
}

.verificationBlockStatus {
  color: var(--c-primary);
  font-weight: 700;
  margin-bottom: 8px;
}

.verificationBlockLink,
.verificationBlockMessage,
.verificationBlockStatus {
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
}

.verificationBlockMessage {
  color: var(--c-grey-darken);
}

.verificationBlockLink {
  display: block;
  color: var(--c-primary);
  text-decoration: underline;
}

.error {
  margin: 15px 0;
  color: var(--c-danger);
}

.forgotLink {
  color: var(--c-primary);
  font-weight: 600;
  line-height: 1.6;
  &:hover {
    text-decoration: underline;
  }
}

.dangerLink {
  color: var(--c-danger);
  font-weight: 600;
  line-height: 1.6;
  &:hover {
    text-decoration: underline;
  }
}
