.listWrapper {
  display: flex;
  flex-direction: column;
  flex: 0 0 75%;
  max-width: 75%;
  padding: 0 12px;
  width: 100%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: var(--c-white);
  z-index: 100;

  &>div {
    width: 70px;
    height: 70px;
    div {
      width: 55px;
      height: 55px;
    }
  }
}
.row {
  width: 100%;
  display: grid;
  grid-template-columns: 86px 120px repeat(3,  minmax(130px, 1fr)) minmax(80px, 130px) minmax(80px, 130px);
  margin-bottom: 8px;
  background: #EAEDF2;
  border: 1px solid #EAEDF2;
  border-radius: 2px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}

.title {
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  text-transform: uppercase;
  color: #2C4877;
  border-right: 1px solid #FFFFFF;

  &:last-child {
    border-right: none;
  }
}

.scrollWrapper {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  position: relative;
  padding-bottom: 20px;
}
