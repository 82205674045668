.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topWrapper {
  margin-top: 10px;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 5px;
  }
}

.textWrapper {
  display: flex;
  margin-top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: var(--c-primary);
}

.error {
  color: var(--c-danger-darken);
}

.ok {
  width: 24px;
  height: 24px;
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;
  background: var(--c-info);
  margin-right: 10px;

  &:before{
    content: '';
    width: 7px;
    height: 4px;
    border-left: 3px solid var(--c-white);
    border-bottom: 3px solid var(--c-white);
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

