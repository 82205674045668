.delimiterRow {
  display: flex;
  padding-bottom: 22px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--c-grey-secondary);
}

.contentBetween {
  justify-content: space-between;
}


.column {
  flex-direction: column;
}

.uppercaseText {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--c-grey-darken);
}

.priceBlock {
  font-family: Rubik,sans-serif;
  padding: 7px 7px 7px 36px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 1.2;
  color: var(--c-primary);
}

.row {
  display: flex;
}

.contentCenter {
  justify-content: center;
}

.contentEnd {
  justify-content: flex-end;
}

.strongText {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--c-primary);
}
