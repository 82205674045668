.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 290px;
  background: var(--c-light-grey);
  border: 1px solid var(--c-grey-secondary);
  border-radius: 2px;
}

.greyTitle {
  text-align: center;
  max-width: 460px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--c-grey-darken);
  white-space: pre-line;
}

.strong {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: var(--c-primary);
  white-space: pre-line;
}


.btn {
  margin-top: 18px;
  min-width: 196px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
  border-radius: 2px;
  transition: .2s ease;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.btnDanger {
  background-color: var(--c-danger);
  color: #fff;
}


