.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.content {
  color: var(--c-grey-darken);
  font-size: 12px;
  line-height: 1.55;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.isActive {
  display: flex;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
