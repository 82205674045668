.wrapper {
  flex: 0 0 75%;
  max-width: 75%;
  padding: 0 12px;
  width: 100%;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.topBlockLabel {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  color: var(--c-primary);
}

.btn {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  color: var(--c-primary);
  position: relative;
  padding: 0;
  height: auto;
  min-width: auto;
  width: fit-content;

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--c-info);
    position: absolute;
    left: 0;
    bottom: 0px;
  }
}

.topBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 48px;
}

.btnsWrapper {
  margin-top: 14px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
