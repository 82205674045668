.navItemLink {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 1.55;
  font-weight: 500;
  color: var(--c-primary);
  position: relative;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: var(--c-grey-secondary);
  }

  &:not(:last-child) {
    margin-bottom: 24px;
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--c-grey-secondary);
      position: absolute;
      left: auto;
      right: 0;
      bottom: -12px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 12px;
  }
}
