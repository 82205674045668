.wrapper {
  position: relative;
  &:hover {
    .popover {
      display: flex;
    }
  }
}
.btn {
  width: 34px;
  height: 34px;
  border-radius: 2px;
  border: 1px solid var(--c-grey-secondary);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3px;
  cursor: pointer;
}

.popover {
  background-color: var(--c-white);
  border-radius: 2px;
  border: 1px solid var(--c-grey-secondary);
  padding: 12px;
  display: none;
  flex-direction: column;
  position: absolute;
  left: auto;
  right: 0;
  top: calc(100% - 1px);
  z-index: 100;
}

.navItemLink {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 1.55;
  font-weight: 500;
  color: var(--c-primary);
  position: relative;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: var(--c-grey-secondary);
  }

  &:not(:last-child) {
    margin-bottom: 24px;
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--c-grey-secondary);
      position: absolute;
      left: auto;
      right: 0;
      bottom: -12px;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 12px;
  }
}

@media (min-width: 1450px) {
  .popover {
    left : 0;
    right: auto;
  }
}
