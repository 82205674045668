@import 'scss/variables';

.sidebar {
     counter-reset: myCounter;
     &-item {
          position: relative;
          margin-bottom: 24px;
          padding-left: 36px;
          a {
               font-size: 18px;
               line-height: 24px;
               font-weight: 500;
               color: var(--c-grey-darken);
          }
          &:before {
               counter-increment: myCounter;
               content: counter(myCounter);
               color: var(--c-grey-darken);
               border: 1px solid var(--c-grey-darken);
               font-size: 14px;
               font-weight: 700;
               line-height: 26px;
               display: inline-block;
               text-align: center;
               width: 24px;
               height: 24px;
               position: absolute;
               left: 0;
          }
          &-current {

               a {
                    color: var(--c-primary);
               }

               &:before {
                    border: none;
                    background: var(--c-primary);
                    color: var(--c-white);
               }
          }
          &-done {
               a {
                    color: var(--c-info);
               }
               &:before {
                    border: none;
                    background: var(--c-info);
                    color: var(--c-white);
               }
          }
     }
}

.sidebarContainer {
     flex: 0 0 33.33333%;
     max-width: 33.33333%;
     padding: 0 12px;
     width: 100%;
}
