
.verificationBlock {
  padding: 16px;
  background: var(--c-light-grey);
  margin-bottom: 24px;
}

.verificationBlockStatus {
  color: var(--c-primary);
  font-weight: 700;
  margin-bottom: 8px;
}

.verificationBlockLink,
.verificationBlockMessage,
.verificationBlockStatus {
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.verificationBlockMessage {
  color: var(--c-grey-darken);
}

.verificationBlockLink {
  display: block;
  color: var(--c-primary);
  text-decoration: underline;
}

.error {
  margin: 15px 0;
  color: var(--c-danger);
}
