.flexColumn {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 18px;
  line-height: 1.6;
  color: var(--c-grey-darken);
  margin-bottom: 24px;
}

.textArea {
  width: 100%;
  min-height: 208px;
  padding: 12px 24px;
  font-size: 18px;
  line-height: 1.55;
  color: var(--c-info);
  border: 1px solid var(--c-grey-secondary);
  border-radius: 2px;
  resize: none;
  outline: none;
  background: transparent;

  &:focus {
    outline: 1px solid var(--c-info);
  }

  &::placeholder {
    font-family: 'Rubik', sans-serif;
    color: var(--c-grey-darken);
  }
}
