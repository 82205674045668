.btnWrapper {
  margin: 62px auto;
}

.textCenter {
  text-align: center;
}

.hideBefore {
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    display: none;
  }
}

.wrapper {
  max-width: 632px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
