.wrapper {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-wrap: wrap;
}

.bottomMargin {
  margin-bottom: 45px;
}
