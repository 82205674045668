.row {
  width: 100%;
  display: grid;
  grid-template-columns: 86px 120px repeat(3,  minmax(130px, 1fr)) minmax(80px, 130px) minmax(80px, 130px);
  margin-bottom: 8px;
  background: #F8F9FB;
  border: 1px solid #EAEDF2;
  border-radius: 2px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 78px;
  padding: 15px 10px;
  position: relative;

  &:after {
    content: '';
    width: 1px;
    height: 22px;
    background: #EAEDF2;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

}

.strong {
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  color: #2C4877;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #2C4877;
}

.smallStrong {
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #2C4877
}



.btn {
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &.notAvailable {
    cursor: not-allowed;
    pointer-events: none;
    touch-action: none;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 54px;
  height: 24px;
  background: #EAEDF2;
  border-radius: 12.5px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #2C4877;
  text-transform: capitalize;

  &.btnPay {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 65px;
    height: 30px;
    background: var(--c-danger);
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}
