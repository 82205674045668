.wrapper {
  text-align: center;
  position: relative;
  width: 100%;
  border-radius: 4px;
  background-color: var(--c-light-grey);
  border: 2px dashed var(--c-grey);
  margin-bottom: 24px;
}

.warning {
  font-size: 18px;
  line-height: 1.6;
  color: var(--c-danger);
  margin-bottom: 24px;
  white-space: pre-line;
}

