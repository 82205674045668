.flexRow {
  display: flex;
  flex-direction: row;
}

.score {
  display: flex;
  position: relative;
  width: fit-content;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  color: var(--c-primary);
  background-color: var(--c-grey-secondary);
  border-radius: 11px;

  svg {
    margin-right: 5px;
  }
}

.text {
  display: flex;
  align-items: center;
  margin-left: 6px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: var(--c-grey-darken);
}

.strong {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  color: var(--c-primary);
}
