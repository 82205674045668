.title {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--c-grey-darken);
  margin: 10px 0 12px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  padding: 12px 24px;
  font-size: 18px;
  line-height: 1.55;
  color: var(--c-info);
  border: 1px solid var(--c-grey-secondary);
  border-radius: 2px;
  background: transparent;
  box-shadow: none;
  outline: none;

  &:focus {
    outline: 1px solid var(--c-info);
  }
  &::placeholder {
    font-family: 'Rubik', sans-serif;
    color: var(--c-grey-darken);
  }

  &.notValid {
    border-color: var(--c-danger);
  }
}

.iconStyles {
  padding-left: 60px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-light-grey);
  font-family: Rubik,sans-serif;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  width: 42px;
  height: 42px;
  color: var(--c-grey-darken);
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.inputWrapper {
  position: relative;
}
