.textLoading {
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: var(--c-primary);
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

