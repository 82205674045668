.wrapper {
  display: flex;
  width: 100%;
}

.flexWrap {
 flex-wrap: wrap;
  flex-grow: 1;
}

.title  {
  width: 255px;
  flex-shrink: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
  color: var(--c-primary);
  margin-right: 24px;
}
