.wrapper {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  min-width: 30px;
  min-height: 30px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.icon {
  display: flex;align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  svg {
    width: 95%;
    height: 95%;
  }
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &>div {
    width: 30px;
    height: 30px;
  }
}
