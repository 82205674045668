.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  padding: 24px 0;
  background-color: var(--c-white);
  z-index: 100;
  transition: 0.2s ease;
}

.logo {
  margin-right: 58px;
}

.nav {
  display: flex;
  align-items: center;
}

.navItem {
  margin-right: 40px;
  position: relative;
  transition: 0.3s;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: var(--c-primary);
    transition: 0.1s;
  }

  &:hover:after {
    width: 100%;
  }
}

.navItemLink {
  font-size: 18px;
  line-height: 1.4;
  color: var(--c-primary);
}

.accountMenu {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.accountMenuItem {
  margin-left: 32px;
}

.accountMenuLink {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--c-primary);

  svg {
    margin-right: 12px;
    width: 20px;
  }
}

.accountMenuButton {
  width: 196px;
  height: 52px;
  font-family: inherit;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  transition: 0.2s ease;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  background-color: var(--c-primary);
}