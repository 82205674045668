.label {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-right: 6px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;

  svg {
    margin-right: 6px;
  }
}

.text {
  max-width: 125px;
}

.info {
  color: var(--c-primary);
  background-color: var(--c-grey-secondary);
}

.danger {
  color: var(--c-white);
  background-color: var(--c-danger);
}


