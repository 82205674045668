.wrapper {
  text-align: center;
  position: relative;
  //width: 306px;
  //height: 306px;
  border-radius: 4px;
  background-color: var(--c-light-grey);
  border: 2px dashed var(--c-grey);
  margin-bottom: 24px;
}
