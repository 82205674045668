.topMargin {
  margin-top: 24px;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &>div {
    width: 30px;
    height: 30px;
  }
}
