.expertBlock {
  margin-bottom: 24px;
  padding: 48px 29px 48px 48px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  background-color: var(--c-light-grey);
  position: relative;
}

.contentCenter {
  display: flex;
  align-items: center;

  a {
    pointer-events: none;
    touch-action: none;
  }
}

.contentRight {
  padding-left: 60px;
  margin-left: auto;
}


.columnWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-left: auto;
  height: 100%;

  &:before {
    content: "";
    height: 100%;
    width: 1px;
    background-color: var(--c-grey-secondary);
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.blockWrapper {
  margin-right: 30px;
  position: relative;
}

.column {
  margin-top: -11px;
  &>div {
    align-items: center;
    flex-direction: column;
  }
}

.image {
  width: 186px;
  height: 186px;
  border-radius: 105px;
  outline: 2px solid var(--c-grey-darken);
  outline-offset: 3px;
}

.btnwrapper {
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: 0;
  margin-bottom: 20px;
  margin-left: auto;
  touch-action: none;
  pointer-events: none;
}

@media (max-width: 1199px) {
  .expertBlock {
    padding: 12px;
  }
  .image {
    width: 140px;
    height: 140px;
  }
}

