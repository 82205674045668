.wrapperCol {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 12px;
  width: 100%;
}

.title {
  font-size: 24px;
  margin-bottom: 12px;
  padding: 0;
  line-height: 1.2;
  font-weight: 700;
  color: var(--c-primary);
}

.block  {
  margin-bottom: 24px;
  min-height: 292px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  background-color: var(--c-light-grey);
  border-radius: 2px;
  border: 1px solid var(--c-grey-secondary);
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  svg {
    margin-bottom: 10px;
  }
}

.text {
  max-width: 160px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  text-align: center;
  color: var(--c-grey-darken);
}
