.btn {
  background-color: var(--c-danger);
  min-width: 196px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-family: inherit;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  line-height: 1.6;
  border-radius: 2px;
  border: 0;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  border: none;
  transition: 0.3s;
  margin-top: 40px;

  &:hover {
    opacity: 0.9;
  }
}
