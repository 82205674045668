.btnsRow {
  display: flex;
  margin-top: 40px;
  margin-bottom: 0;
  white-space: pre-line;
}

.contentBetween {
  justify-content: space-between;
}

.contentStart {
  justify-content: flex-start;
}

.mlAuto {
  margin-left: auto;
}

.mrZero {
  margin-right: 0;
}


.mlZero {
  margin-left: 0;
}


.mrAuto {
  margin-right: auto;
}

.mrGrid {
  margin-right: 16px;
}

.bgDanger {
  background: var(--c-danger);
  &:hover {
    background: var(--c-danger);
  }
}
