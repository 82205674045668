.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 60px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.outline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(44,72,119,.6);
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 662px;
  max-height: 100%;
  margin: auto  ;
  background-color: var(--c-white);
  position: relative;
  z-index: 10;
}

.header {
  padding: 24px 34px;
  background-color: var(--c-light-grey);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body {
  overflow-y: auto;
  padding: 14px 34px 24px;
}

.footer {
  display: flex;
}

.cross {
  height: 24px;
  width: 24px;
  border: none;
  cursor: pointer;
  position: relative;
  background: transparent;
  outline: none;
  border: none;

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    top: 50%;
    background: var(--c-grey-darken);
    transition: 0.3s;
  }

  &:before {
  transform: translateY(-50%) rotate(-45deg);
  }

  &:after {
    transform: translateY(-50%) rotate(45deg);
  }

  &:hover {
    &:after,
    &:before {
      background: var(--c-info);
    }
  }
}

.title {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  color: var(--c-primary);
}

.children {
  margin-bottom: 24px;
}

.contentBetween {
  justify-content: space-between;

  & *:nth-child(2) {
    margin-left: 30px;
  }
}

.row {
  display: flex;
}

.bottomLabel {
  align-self: flex-end;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #96A4BB;
  margin-left: auto;
}

