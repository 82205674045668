.tag {
  display: flex;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  color: var(--c-primary);
  background-color: var(--c-grey-secondary);
  margin-bottom: 8px;
  margin-right: 8px;
}
