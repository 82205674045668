.footer {
  background-color: var(--c-light-grey);
  padding: 48px 0;
}

.footerBlockInfo {
  margin-bottom: 8px;
}

.copyright {
  font-size: 14px;
  color: var(--c-primary-lighten);
}

.footerBlockContacts {
  padding-top: 14px;
}

.navItem {
  margin-bottom: 12px;
}

.navItemLink {
  font-size: 14px;
  color: var(--c-primary-lighten);
  transition: 0.2s ease;

  &:hover {
    color: var(--c-primary);
  }
}

.footerBlockSocial {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 18px;
  padding-top: 12px;
}

.developed {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--c-primary-lighten);
  font-size: 14px;
}

.developedImg {
  display: flex;
  padding-left: 8px;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
}

.socialItem {
  margin-left: 34px;
}

.socialLink {
  display: flex;
  transition: 0.2s ease;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
