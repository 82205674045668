.h2Title {
  font-size: 36px;
  margin-bottom: 29px;
  line-height: 1.2;
  font-weight: 700;
  color: var(--c-primary);
}

.beforeHide {
  padding: 24px 0 70px;
  &:before {
    display: none;
  }

}

.h3Title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--c-primary);
}

div.verticalPadding {
  padding-top: 14px;
  padding-bottom: 14px;
  margin-bottom: 12px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
  }

  &:before {
    top: 0;
    bottom: -12px;
    left: 20px;
    right: 20px;
    background: linear-gradient( 90deg,#fff 25.5%,#f8f9fb 0,#f8f9fb 49%,#fff 0,#fff 51%,#f8f9fb 0,#f8f9fb 74.5%,#fff 0,#fff 76.5%,#f8f9fb 0);
  }

  &:after {
    height: 100%;
    top: 0;
    left: 20px;
    right: 20px;
    background: rgba(44,72,119,.02);
    border: 1px solid rgba(44,72,119,.03);
  }
  &.hideBefore {
    &:before {
      bottom: 0;
    }

    &.hideAfter {
      &:after {
        left: calc(100% / 4 + 18px);
      }
    }
  }
}

.alignCenter {
  display: flex;
  align-items: center;
  height: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.strongTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--c-primary);

  span {
    display: inline-block;
    margin-left: 5px;
    color: var(--c-grey-darken);
  }
}

.contentPadding {
  padding: 0 24px;
}

.bottomPadding {
  padding-bottom: 10px;
}

.readMoreBtnText {
  font-size: 12px;
  font-weight: 500;
  &:hover {
    opacity: 0.7;
  }

}

.label {
  background-color: #e1e6ef;
  border-radius: 8px;
  margin-right: 4px;
  margin-bottom: 4px;

  button {
    background-color: #e1e6ef;
    font-size: 12px;
    padding: 3px 12px;
    pointer-events: none;
    touch-action: none;
    &:hover {
      background-color: #e1e6ef;
      border-color: #e1e6ef;
    }
  }
}

.mrAuto {
  margin-right: auto;
}

div .contentLimitRows {
  -webkit-line-clamp: 4;
  white-space: break-spaces;
  max-width: 100%;
}

.btnWrapper {
  margin-right: auto;
  margin-left: 12px;
}

.textCenter {
  text-align: center;
}

.hideBeforeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:before {
    display: none;
  }
}

.wrapper {
  max-width: 632px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.toBottomLeft {
  margin: auto auto 0 0;
}

.center {
  margin: auto;
}

.link {
  display: inline-block;
  position: relative;
  color: var(--c-primary);

  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--c-primary);
    transition: 0.3s;
  }
  &:hover {
    &:after {
      width: 10%  ;
    }
  }
}

.subtitle {
  max-width: 420px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  text-align: center;
  color: var(--c-grey-darken);
}

@media (max-width: 1199px) {
  .contentPadding {
    padding: 0 8px;
  }
}
