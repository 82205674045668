.innerPage {
  padding: 48px 0 100px;
  min-height: 72.5vh;

  &:before {
    display: none;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: var(--c-white);
  z-index: 100;

  &>div {
    width: 70px;
    height: 70px;
    div {
      width: 55px;
      height: 55px;
    }
  }
}
