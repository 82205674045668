.see {
  width: 100%;
  height: 76px;
  background: #F8F9FB;
  border: 2px solid #DD8C93;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 16px  48px;
  padding: 0 16px;
}

.seeYourProfile {
  display: flex;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
  padding: 0 12px;
  width: 100%;
  font-size: 18px;
  line-height: 1.6;
  color: var(--c-primary);
  font-weight: 400;
  margin-right: 34px;

  svg {
    margin-right: 12px;
  }
}

.seeLink {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: calc(50% - 6px);
  margin-left: 12px;
  background: #0294B2;
  border-radius: 2px;
  color: var(--c-white);
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  text-transform: none;
  position: relative;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.nativeBtnStyle {
  width: calc(50% - 6px);
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 12px;
  width: 100%;
}

@media (max-width: 1100px) {
  .see {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 16px;

    &>div {
      width: 100%;
      flex: 0 0 auto;
      max-width: 100%;
      justify-content: center;
    }
  }

  .seeLink {
    width: 196px;
  }
}
