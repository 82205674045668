.wrapper  {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
  padding: 0 12px;
  width: 100%;
}

.title {
  font-size: 24px;
  margin-bottom: 12px;
  padding: 0;
  line-height: 1.2;
  font-weight: 700;
  color: var(--c-primary);
}

.additional {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 48px 48px 24px;
  background-color: var(--c-light-grey);

  &>div {
   padding: 14px 0;
  }

  &>div:not(:last-child) {
   border-bottom: 1px solid var(--c-grey-secondary);
  }
}
.boldLargeText {
  padding: 0;
  font-size: 18px;
  line-height: 1.4;
  color: var(--c-primary);
  font-weight: 500;
}

.flexRow {
  display: flex;
}

.w100 {
  width: 100%;
}

.icon {
  flex-shrink: 0;
}

.btnLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  font-size: 12px;
  color: var(--c-primary);
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  transition: 0.3s;
  min-width: 75px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 7px;
    left: 0;
    background-color: var(--c-danger);
  }

  svg {
    margin-left: 5px;
    margin-bottom: 2px;
  }
}

.contentBetween {
  justify-content: space-between;
}

.textMedium {
  display: flex;
  align-items: center;
  margin-left:  10px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-weight: 400;
  color: var(--c-grey-darken);
}

.greyText {
  display: inline-block;
  margin-left: 5px;
  color: var(--c-grey-darken);
  font-weight: 500;
  line-height: 1.6;
}

.textSmall {
  font-size: 14px;
  line-height: 1.55;
  font-weight: 400;
  color: var(--c-grey-darken);
}

.achieves {
  white-space: break-spaces;
  max-width: 100%;
}

@media (max-width: 1199px) {

  .additional {
    padding: 20px;
  }
}
