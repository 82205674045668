.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 82px 48px 48px;
  &:hover {
    .btn {
      box-shadow: 0 0 8px var(--c-primary);
    }
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.relative {
  position: relative;
}

.btn {
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 196px;
  height: 42px;
  border: 2px solid var(--c-primary);
  border-radius: 2px;
  color: var(--c-primary);
  font-size: 18px;
  font-weight: 500;
  position: relative;
  z-index: 5;
  margin: 5px auto;
  transition: .2s ease;
  cursor: pointer;
}

.text {
  margin-bottom: 15px;
  color: var(--c-grey-darken);
  font-size: 16px;
  padding: 10px 15px;
  display: block;
  z-index: 20;
}


.input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  margin-top: 10px;
}
