.wrapper {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 12px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--c-light-grey);
  height: 430px;
  overflow-y: auto;
  border: 1px solid var(--c-grey-secondary);
  box-sizing: border-box;
  border-radius: 2px;
}
