:root {
    --c-white: #fff;
    --c-dark: #000;
    --c-primary: #2C4877;
    --c-danger: #DD8C93;
    --c-info: #0294B2;
    --c-warning: #FFC000;
    --c-secondary: #B9BBA2;
    --c-primary-darken: #233a5f;
    --c-danger-darken: #b17076;
    --c-info-darken: #02768e;
    --c-warning-darken: #FFC000;
    --c-primary-lighten: #abb6c9;
    --c-danger-lighten: #FAEEEF;
    --c-info-lighten: #D9EFF4;
    --c-warning-lighten: #FFF6D9;
    --c-secondary-lighten: #F5F5F1;
    --c-light-grey: #F8F9FB;
    --c-grey: #e9ecf1;
    --c-grey-darken: #96A4BB;
    --c-grey-secondary: #EAEDF2;
}

$xs: 575px;
$sm: 768px;
$md: 991px;
$lg: 1200px;
$xl: 1450px;
