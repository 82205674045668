@import 'scss/scss-variable';
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  padding: 24px 0;
  background-color: var(--c-white);
  z-index: 100;
  transition: 0.2s ease;
  box-shadow: 0 4px 10px -2px rgba(234, 237, 242, 0.5);

  .logo {
    margin-right: 58px;
  }
}

.headerContent {
  width: 100%;
  max-width: 100%;
  padding: 0 $grid-gutter;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
}

.leftMargin {
  display: flex;
  margin-left: auto;

  *:nth-child(2) {
    margin-left: 30px;
  }
}

.btn {
  min-width: 196px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
  border-radius: 2px;
  transition: .2s ease;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.btnTransparent {
  color: var(--c-primary);
  border: 1px solid var(--c-primary);
  border-radius: 2px;
}

.btnDanger {
  background-color: var(--c-danger);
  color: #fff;
}
