.wrapper {
  display: flex;
  flex-direction: column;

  &>button {
    padding: 6px 25px;
    transition: 0.3s;

    &.active,
    &:hover {
      background: var(--c-grey-secondary);
    }
  }

  svg {
    margin-right: 8px;
    margin-top: -4px;
    transition: 0.3s;
  }
}

.fitContent  {
  min-width: auto;
  width: 100%;
  justify-content: flex-start;
  height: auto;
  font-size: 14px;
}
