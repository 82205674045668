.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.delimiter {
  width: 8px;
  height: 2px;
  margin-left: 8px;
  margin-right: 8px;
  background: var(--c-grey-darken);
}

.inputRate {
  width: 196px;
}

.inputMail {
  width: 416px;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: var(--c-info);
}

.contentStart {
  justify-content: flex-start;

  svg {
    margin-right: 16px;
  }

  button {
    margin-left: auto;
    margin-right: 0;
  }
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--c-primary);
}
