.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  &:hover {
    .btn {
      box-shadow: 0 0 8px var(--c-primary);
    }
  }
}

.wrapperPadding {
  padding: 82px 48px 48px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexRow {
  display: flex;
}

.relative {
  position: relative;
}

.btn {
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 196px;
  height: 42px;
  border: 2px solid var(--c-primary);
  border-radius: 2px;
  color: var(--c-primary);
  font-size: 18px;
  font-weight: 500;
  position: relative;
  z-index: 0;
  margin: 5px auto;
  transition: .2s ease;
  cursor: pointer;
}

.text {
  margin-bottom: 15px;
  color: var(--c-grey-darken);
  font-size: 16px;
  padding: 10px 15px;
  display: block;
  z-index: 20;
}

.textLoading {
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: var(--c-primary);
}

.input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
}

.zIndexNegative {
  z-index: -1;
}

.flexRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.largeText {
  cursor: pointer;
  width: fit-content;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: var(--c-primary);
  position: absolute;
  left: 50%;
  bottom: -37px;
  transform: translateX(-50%);
  z-index: 1;

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: var(--c-info);
  }

  .error {
    color: var(--c-danger)
  }
}

.w100{
  width: 100%;
}

