.wrapper {
  padding: 4px 12px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--c-grey-secondary);
  border-radius: 12px;
  color: var(--c-primary);
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}
