.wrapper {
  display: flex;
  align-items: center;
  flex: 0 1 calc(100% / 4 - 10px);
  padding: 8px;
  border-radius: 2px;
  background-color: var(--c-light-grey);
  border: 1px solid var(--c-grey-secondary);
  justify-content: flex-start;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  max-width: calc(100% / 4 - 10px);
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 0.85;
  }
}

.imageWrapper {
  position: relative;
}

.onlineLabel {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border: 2px solid var(--c-light-grey);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  z-index: 10;
}

.labelExpert {
  background-color: var(--c-info);
}

.labelFounder {
  background-color: var(--c-info);
}

.personal {
  max-width: 110px;
  margin-left: 12px;
  margin-right: 12px;
}

.name {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  color: var(--c-primary);
  text-align: left;
  max-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.textGray {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  color: var(--c-grey-darken);
  text-align: left;
}

.activeNewMessages {
  flex-shrink: 0;
  margin-left: auto;
  margin-right: 0;
  min-width: 24px;
  min-height: 24px;
  max-width: 38px;
  padding: 5px;
  color: var(--c-white);
  font-size: 11px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  position: relative;

  span {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background: inherit;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
  }

}

.activeNewMessagesExpert {
  background-color: var(--c-info);
}


.activeNewMessagesFounder {
  background-color: var(--c-danger);
}

.img {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  background-color: var(--c-grey-secondary);
  border: 1px solid var(--c-grey-darken);
}


@media (max-width: 1199px) {
  .lgWrapper {
    flex-direction: column;

    .imageWrapper {
      margin-bottom: 12px;
    }

    .name,
    .textGray {
      text-align: center;
      margin-bottom: 2px;
    }
  }
}
