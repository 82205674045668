.flexRow {
  display: flex;
  flex-wrap: wrap;

  .btn {
    height: 15px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    color: var(--c-primary);
    font-size: 10px;
  }
}

.w100 {
  width: 100%;
}

.inputWrapper {
  width: 100%;
}

.wrapperMargin {
  margin-right: 8px;
  margin-bottom: 8px;
}

.btnMore {
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

