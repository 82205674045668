.wrapper {
  display: flex;
  align-items: center;
}

.text {
  display: block;
  color: var(--c-primary);
  text-align: right;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -3px;
    left: 0;
    background-color: var(--c-danger);
  }
}

.marginRight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.marginLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
