@import '../../../../scss/scss-variable';

.row {
  display: flex;
  flex-wrap: wrap;
}

.wrapper {
  width: calc(100% / 2 - 12px);
  margin-bottom: 2 * $grid-gutter;
}

.mr {
  margin-right: 2 * $grid-gutter;
}
