.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.title {
  padding-right: 12px;
  text-align: right;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--c-grey-darken);
  margin-bottom: 5px;
}
