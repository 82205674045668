.innerPage {
  min-height: calc(100vh - 100px);
  padding: 68px 0;
  position: relative;
  margin-top: 100px;
  &:before {
    content: "";
    height: 100%;
    width: 40%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--c-light-grey);
  }
}
