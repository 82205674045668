.img {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--c-primary);
}

.label {
  font-weight: 400;
  color: var(--c-grey-darken);
}

.data {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--c-grey-darken);
}

.top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 6px 0;
}

.wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
}

.message {
  padding: 12px;
  background: var(--c-white);
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  color: var(--c-grey-darken);
  white-space: pre-line;
  word-break: break-word;
}
