.wrapper {
  flex: 0 0 75%;
  max-width: 75%;
  padding: 0 12px;
  width: 100%;
}

.inner {
  padding: 0 12px 24px;
  background: var(--c-light-grey);
  border: 1px solid var(--c-grey-secondary);
  border-radius: 2px;
}
