.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid var(--c-grey-secondary);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  color: var(--c-primary);
  cursor: pointer;
  outline: none;
  background: transparent;

  &:hover {
    border: 1px solid var(--c-light-grey);
    background-color: var(--c-light-grey);
  }

  &.bntInfo {
    background-color: var(--c-info);
    color: var(--c-white);

    :hover {
      background-color: var(--c-info-darken);
    }
  }
  &.bntDanger {
    background-color: var(--c-danger);
    color: var(--c-white);

    :hover {
      background-color: var(--c-danger-darken);
    }
  }
}

.bntInfo {
  background-color: var(--c-info);
  color: var(--c-white);

  :hover {
    background-color: var(--c-info-darken);
  }
}

.bntDanger {
  background-color: var(--c-danger);
  color: var(--c-white);

  :hover {
    background-color: var(--c-danger-darken);
  }
}

