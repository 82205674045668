.toRight {
  margin-left: auto;
}

.spinner {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
