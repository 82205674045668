.wrapper {
  position: relative;

  &:after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: var(--c-grey-secondary);
    position: absolute;
    left: 0;
    bottom: -10px;
    z-index: -1;
  }
}

.btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  border: none;
  color: var(--c-grey-darken);
  background: none;
  margin-bottom: 10px;
  position: relative;
  width: fit-content;

  &:after {
    content: "";
    height: 4px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -10px;
  }
}

.btnExpert {
  color: var(--c-info);

  &:after {
    background: var(--c-info);
  }
}

.btnFounder {
  color: var(--c-danger);
  &:after {
    background: var(--c-danger);
  }
}
