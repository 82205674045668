.wrapper {
  display: flex;
  width: 100%;
  background: var(--c-light-grey);
  padding: 24px;
  margin-bottom: 12px;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: -12px;
    left: 20px;
    right: 20px;
    background: linear-gradient( 90deg,#fff 25.5%,#f8f9fb 0,#f8f9fb 49%,#fff 0,#fff 51%,#f8f9fb 0,#f8f9fb 74.5%,#fff 0,#fff 76.5%,#f8f9fb 0);
  }
}
.block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  margin-left: 12px;
}

.contentCenter {
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.name {
  max-width: 100%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: var(--c-primary);
  text-overflow: ellipsis;
  overflow: hidden;
}

.imageWrapper {
  position: relative;
  flex-shrink: 0;
  width: 86px;
}

.labelText {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--c-grey-darken);
  margin-bottom: 4px;
  &:after {
    display: none;
  }
}

.smallText{
  font-size: 12px;
}



.img {
  border: 1px solid var(--c-grey-darken);
  width: 86px;
  height: 86px;
}

.onlineLabel {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border: 2px solid var(--c-light-grey);
  position: absolute;
  top: 5px;
  left: 12px;
  border-radius: 100%;
  z-index: 10;
  background-color: var(--c-danger);
}

.ratingBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  padding: 4px 6px 4px 6px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  color: var(--c-primary);
  background-color: var(--c-grey-secondary);
  border-radius: 11px;

  svg {
    margin-right: 5px;
  }
}


.textUppercase {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-weight: 500;
  text-transform: uppercase;
}

@media (max-width: 1199px) {
  .wrapper {
    padding: 16px 8px;
  }
}
