.wrapper {
  display: flex;
  flex-direction: column;
  background: var(--c-light-grey);
  border: 1px solid var(--c-grey-secondary);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 24px;
  margin-bottom: 12px;
  position: relative;

  button {
    font-size: 12px;
  }
}

.top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--c-grey-secondary);
}

.img {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  &>div {
    width: 100%;
    height: 100%;
  }
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 2px;
  color: var(--c-primary);
}

.ratingBlock {
  margin-left: auto;
  background: var(--c-grey-secondary);
  border-radius: 11px;
  padding: 3px 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: var(--c-primary);
  display: flex;
  align-items: center;
   svg {
     margin-right: 4px;
   }
}

.footer {
  position: absolute;
  right: 24px;
  bottom: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  text-align: right;
  color: var(--c-grey-darken);
}

.margin {
  margin-bottom: 24px;
}

.spinnerWrapper {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  &>div {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 1199px) {
  .wrapper {
    padding: 12px;
  }
}
