.wrapper {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 12px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--c-light-grey);

  .asideBlock:last-child {
    margin-bottom: 0;
    &:after {
      display: none;
    }
  }
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  max-width: 126px;
  height: 126px;
  position: relative;
  margin-bottom: 13px;
  outline-offset: 3px;
}

.outlineExpert {
  outline: 2px solid var(--c-info);
}

.outlineFounder {
  outline: 2px solid var(--c-danger);
}

.title {
  text-align: center;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--c-primary);
}

.alignCenter {
  align-items: center;
}



.asideBlock {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 49px;

  &:after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: var(--c-grey-secondary);
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -24px;
  }

}

.sidebarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 0 25px;
  position: relative;
}

.errorMsg {
  display: none;
  width: 190px;
  position: absolute;
  bottom: 110%;
  right: 0;
  padding: 8px;
  border-radius: 2px;
  background-color: var(--c-light-grey);
  border: 1px solid var(--c-grey-secondary);
  justify-content: flex-start;
  cursor: pointer;
  color: var(--c-danger);
}

.relative {
  position: relative;

  &:hover {
    & .errorMsg {
      display: flex;
    }
  }
}

.available {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--c-primary);
}

.textGray {
  font-size: 12px;
  font-weight: 400;
  color: var(--c-grey-darken);
  line-height: 1.6;
  padding: 0 25px;
}

@media (max-width: 1199px) {
  .container {
    padding: 16px 8px;
  }
  .sidebarWrapper,
  .textGray {
   padding: 0 8px;
  }
}
