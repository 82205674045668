.wrapper {
  width: 100%;
  padding: 8px 12px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--c-grey-secondary);
  background: var(--c-light-grey);
  outline: none;
  cursor: pointer;
  position: relative;

  &:hover,
  &.active {
    background: var(--c-grey-secondary);

    .leftBorder {
      display: flex;
    }
  }
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  padding: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
  color: var(--c-white);
  border-radius: 50%;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background: var(--c-danger);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-top: 0px;
  }
}

.bgInfo {
  background: var(--c-info);
}

.bgDanger {
  background: var(--c-danger);
}

.leftBorder {
  display: none;
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

