.wrapper {
  display: flex;
  align-items: center;
  padding: 20px 24px 20px 12px;
  border-radius: 2px;
  border: 1px solid var(--c-grey-secondary);
  background-color: var(--c-light-grey);
  justify-content: space-between;
  margin-bottom: 12px;
}

.flexCol {
  display: flex;
  flex-direction: column;
  margin-right: 6px;
}

.colStart {
  align-items: flex-start;
}

.titleStrong {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  color: var(--c-primary);
}

.titleGray {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--c-grey-darken);
  margin-bottom: 5px;
}

.img {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-right: 12px;
  border: 1px solid var(--c-grey-darken);
}

.flexRow {
  display: flex;
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.blockWrapper {
  margin-right: 24px;
}

.btn {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  color: var(--c-primary);
  position: relative;
  padding: 0;
  height: auto;
  min-width: auto;
  width: fit-content;
  margin-bottom: 2px;
  margin-right: 12px;

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--c-info);
    position: absolute;
    left: 0;
    bottom: -1px;
  }
}

.toRight {
  margin-left: auto;
}

.spinner {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expertName {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--c-primary);
  margin-top: 4px;
}

.popupAvatar {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.popupBudget {
  &:after {
    display: none;
  }
}

.divider {
  margin: 24px 0;
  height: 1px;
  background-color: var(--c-grey-secondary);
}

.textarea {
  font-weight: 400;
  font-size: 14px;
}

.textareaTitle {
  margin-top: 40px;
}

.rateSection {
  cursor: pointer;
}

.rateBadge {
  margin-top: -8px;
  width: 48px;
}

.draft {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  margin-right: 12px;
  border-radius: 50%;
  border: 1px solid var(--c-grey-darken);
  display: flex;
  align-items: center;
  justify-content: center;
}
