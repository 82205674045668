.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.delimiter {
  width: 8px;
  height: 2px;
  margin-left: 8px;
  margin-right: 8px;
  background: var(--c-grey-darken);
}

.inputRate {
  width: 196px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--c-grey-darken);
}

.inputBudget {
  width: 416px;
}

.flexWrap {
  flex-wrap: wrap;
}
