.wrapper  {
  padding: 34px 24px;
}

.title {
  text-align: center;
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--c-primary);
}

.text {
  text-align: center;
  max-width: 420px;
  font-size: 14px;
  line-height: 1.55;
  font-weight: 400;
  color: var(--c-grey-darken);
  margin: 0 auto 10px;
}

.icon {
  margin: 0 auto 24px;
  width: 110px;
  height: 110px;
}
