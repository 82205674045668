.expertHourly {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: right;
  color: var(--c-grey-darken);
}

.expertRate {
  font-size: 36px;
  text-align: right;
  line-height: 1.2;
  font-weight: 700;
  color: var(--c-primary);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.text {
  white-space: nowrap;
}

@media (max-width: 1199px) {
  .expertRate {
    font-size: 28px;
  }
}
