.text {
  color: var(--c-grey-darken);
  font-size: 16px;
  padding: 10px 15px;
  display: block;
  z-index: 20;
  white-space: pre-line;
}

.error {
  color: var(--c-danger);
}
