.cross {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 0;
  border-radius: 50%;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  background: var(--c-grey-secondary);
  position: relative;
  border: none;
}
