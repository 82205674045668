.title {
  text-align: center;
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--c-primary);
}


.icon {
  margin: 0 auto 24px;
  width: 110px;
  height: 110px;
}

.wrapper  {
  padding: 34px 24px;
}

