.wrapper {
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: var(--c-grey-darken);
  margin-bottom: 18px;
}

.flexRow {
  display: flex;
  flex-wrap: wrap;
}

.bottomMargin {
  margin-bottom: 45px;
}
