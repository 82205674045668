@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');
* {
  box-sizing: border-box;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  position: relative;
}

body {
  min-height: calc(100vh - 100px);
}

#root {
  height: 100%;
}

html body {
  font-family: 'Rubik', sans-serif;
  line-height: 1.2;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: #00a9ff;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.container {
  width: 1320px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.bg-image {
  position: absolute;
  width: 100%;
  height: 100%;
}

button {
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.ReactCrop {
  display: flex!important;
  align-items: center;
  justify-content: center;
  &>div {
    height: 100%;
  }
}

$grid-columns: 12 !default;
$grid-gutter: 12px !default;
$breakpoint: ( xs: 575px, sm: 768px, md: 991px, lg: 1200px, xl: 1450px) !default;

.row {
  margin: 0 (-$grid-gutter);
  display: flex;
  flex-wrap: wrap;

  &.-no-gap {
    [class*="col-"] {
      padding: 0;
    }
  }
}

[class*="col-"] {
  padding: 0 $grid-gutter;
  width: 100%;
}

@each $key, $val in $breakpoint {
  @media all and (min-width: $val) {
    @for $i from 1 through $grid-columns {
      .col-#{$key}-#{$i} {
        flex: 0 0 100% / $grid-columns * $i;
        max-width: 100% / $grid-columns * $i
      }
    }
    @for $i from 1 through $grid-columns {
      .offset-#{$key}-#{$i} {
        margin-left: 100% / $grid-columns * $i;
      }
    }
  }
}

.flex-v-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.color-primary {
  color: var(--c-primary) !important;
}

.color-white {
  color: var(--c-white) !important;
}

.color-danger {
  color: var(--c-danger) !important;
}

.color-info {
  color: var(--c-info) !important;
}

.color-warning {
  color: var(--c-warning) !important;
}

.color-secondary {
  color: var(--c-secondary) !important;
}

.color-dark {
  color: var(--c-dark) !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.inner-page {
  margin-top: 100px;
}

a.disabled {
  pointer-events: none;
}
