.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: var(--c-grey-secondary);
  &>div {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    div {
      width: 60px;
      height: 60px;
    }
  }
}

.popupWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexRow {
  display: flex;
  align-items: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
  margin-right: 6px;
}

.colStart {
  align-items: flex-start;
}

.titleGray {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--c-grey-darken);
  margin-bottom: 5px;
}


.titleStrong {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  color: var(--c-primary);
}

.alignEnd {
  align-items: flex-end;
}

.popupBudget {
  &:after {
    display: none;
  }
}

.divider {
  margin: 24px 0;
  height: 1px;
  background-color: var(--c-grey-secondary);
}

.expertName {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--c-primary);
  margin-top: 4px;
}

.img {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-right: 12px;
  border: 1px solid var(--c-grey-darken);
}

.rateBadge {
  margin-top: -8px;
  margin-left: 7px;
  width: 34px;
  padding: 2px;
}

.expertName {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--c-primary);
  margin-top: 4px;
}

.rateSection {
  cursor: pointer;
}

.textareaTitle {
  margin-top: 40px;
}

.error {
  text-transform: none;
  font-size: 12px;
  font-weight: normal;
  color: var(--c-danger);
}

