.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--c-grey-secondary);
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 0;
    transform: translateY(-50%);
  }
}

.inner {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #96A4BB;
  background: #F8F9FB;
  border: 1px solid #EAEDF2;
  border-radius: 8px;
  padding: 5px 10px;
  position: relative;
  z-index: 1;
}

.loader {
  margin-bottom: auto;
}

.containerInner {
  min-height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
