.wrapper {
  height: 54vh;
  display: flex;
  flex-direction: column;
}

.messagesWrapper {
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  overflow-y: auto;
  padding: 12px 0;
}

.inputWrapper {
  position: relative;
}

.input {
  background: var(--c-white);

  textarea {
    min-height: auto;
    max-height: 210px ;
    padding: 11px 60px 11px 12px;
    border: 1px solid var(--c-grey-secondary);
    border-radius: 2px;
    color: var(--c-grey-darken);
    resize: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.btn {
  width: 42px;
  height: 42px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 4px;
  bottom: 4px;
  transition: 0.2s;
  border: none;
  outline: none;

  &:hover {
    opacity: 0.9;
  }
}

.bgInfo {
  background: var(--c-info);
}

.bgDanger {
  background: var(--c-danger);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

}
