.wrapper {
  padding: 8px 12px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--c-grey-secondary);
}

.column {
  max-width: 69%;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  overflow: hidden;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--c-primary);
  text-align: left;
}

.overflow {
  max-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.subtitle {
  text-overflow: ellipsis;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--c-grey-darken);
}

.image {
  width: 60px;
  height: 60px;
}

.box {
  max-width: 240px;
  display: flex;
  align-items: center;
  flex-grow: 0.85;
  width: calc(100% - 60px);
}

.ratingBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  padding: 4px 6px 4px 6px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  color: var(--c-primary);
  background-color: var(--c-grey-secondary);
  border-radius: 11px;

  svg {
    margin-right: 5px;
  }
}

.imageWrapper {
  position: relative;
}


.flexRow {
  display: flex;
  flex-wrap: wrap;

  span {
    display: inline;
    margin-right: 5px;
  }
}
