.beforeHide {
  padding: 0 0 48px;
  &:before {
    display: none;
  }
}

.contentPadding {
  padding-top: 60px;
}
