.wrapper {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background: var(--c-grey-secondary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
}

.inner {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(to right, var(--c-grey-darken) 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: load 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: var(--c-grey-darken);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: var(--c-grey-secondary);
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
